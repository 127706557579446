.quiz-page-background {
    background: linear-gradient(135deg, #FFF0F5 0%, #FFB6C1 25%, #FFC0CB 50%, #FFB6C1 75%, #FFF0F5 100%);
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Roboto', sans-serif;
    padding: 20px;
  }
  
  .quiz-main-container {
    background-color: white;
    border-radius: 20px;
    padding: 30px 40px 40px;
    width: 100%;
    max-width: 800px;
    min-height: 500px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    animation: containerAppear 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55) forwards;
    transition: all 0.3s ease-in-out;
  }
  
  @keyframes containerAppear {
    0% {
      opacity: 0;
      transform: scale(0.8) translateY(30px) rotate(-3deg);
      box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    }
    70% {
      opacity: 1;
      transform: scale(1.03) translateY(-5px) rotate(1deg);
    }
    100% {
      opacity: 1;
      transform: scale(1) translateY(0) rotate(0);
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }
  }
  
  .quiz-heading {
    font-family: 'Outfit', sans-serif;
    font-weight: 700;
    color: #333;
    font-size: 32px;
    text-align: center;
    margin-bottom: 40px;
    position: relative;
    padding-bottom: 15px;
  }
  
  .quiz-heading::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 3px;
    background: linear-gradient(90deg, #FFB6C1, #4A7299);
    border-radius: 2px;
  }
  
  .quiz-question-text {
    font-size: 22px;
    margin-bottom: 30px;
    text-align: center;
    color: #4A7299;
    line-height: 1.4;
    font-weight: 500;
    padding: 0 20px;
    position: relative;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.05);
    min-height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: min-height 0.3s ease-in-out;
  }
  
  .quiz-question-text::before {
    content: '';
    position: absolute;
    top: -10px;
    left: 0;
    right: 0;
    bottom: -10px;
    background: linear-gradient(to right, rgba(255, 182, 193, 0.1), rgba(74, 114, 153, 0.1));
    border-radius: 10px;
    z-index: -1;
  }
  
  .quiz-scale-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 100%;
    gap: 10px;
  }
  
  .quiz-scale-button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid #A7C1D9;
    background-color: #fff;
    color: #4A7299;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
  }
  
  .quiz-scale-button:hover {
    background-color: #F0F8FF !important;
    transform: scale(1.1) !important;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
  }
  
  .quiz-scale-button:has(~ .quiz-scale-button:hover),
  .quiz-scale-button:hover {
    background-color: #F0F8FF !important;
    transform: scale(1.1) !important;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
  }
  
  .quiz-scale-button {
    transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .quiz-scale-legend {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: #666;
    width: 100%;
    margin-top: 10px;
    padding: 0 10px;
  }
  
  .quiz-progress-text {
    margin-top: 20px;
    font-style: italic;
    color: #4A7299;
  }
  
  .quiz-button-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    width: 100%;
  }
  
  .quiz-back-button {
    padding: 12px 24px;
    background-color: white;
    color: #789DBC;
    border: 2px solid #789DBC;
    border-radius: 24px;
    font-size: 18px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
  }
  
  .quiz-back-button:hover {
    background-color: #789DBC;
    color: white;
  }
  
  .quiz-back-button:disabled {
    background-color: #ccc;
    color: #666;
    cursor: not-allowed;
    border-color: #ccc;
  }
  
  @keyframes popSelect {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.15);
      background-color: #4A7299;
      color: white;
      border-color: #4A7299;
    }
    100% {
      transform: scale(1);
    }
  }
  
  .quiz-scale-container.selecting .quiz-scale-button {
    background-color: #fff !important;
    transform: none !important;
    box-shadow: none !important;
    transition: all 0.3s ease;
  }
  
  .quiz-scale-button.pop-animation {
    animation: popSelect 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
    position: relative;
    overflow: visible;
  }
  
  .quiz-scale-button.pop-animation::before {
    content: '';
    position: absolute;
    top: -4px;
    left: -4px;
    right: -4px;
    bottom: -4px;
    border: 2px solid #4A7299;
    border-radius: 50%;
    animation: ringFade 0.4s ease-out forwards;
  }
  
  @keyframes ringFade {
    0% {
      transform: scale(0.8);
      opacity: 1;
    }
    100% {
      transform: scale(1.2);
      opacity: 0;
    }
  }
  
  .quiz-question-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    transition: all 0.3s ease-in-out;
  }