.page-background-loading {
    background: linear-gradient(
        135deg,
        #FFC0CB 0%,
        #FEF9F2 20%,
        #FFC0CB 40%,
        #FEF9F2 60%,
        #FFC0CB 80%,
        #FEF9F2 100%
    );
    background-size: 1000% 1000%;
    animation: gradientShift 120s ease infinite, colorPulse 40s ease-in-out infinite;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}

@keyframes gradientShift {
    0% { background-position: 0% 0%; }
    25% { background-position: 50% 50%; }
    50% { background-position: 100% 100%; }
    75% { background-position: 50% 50%; }
    100% { background-position: 0% 0%; }
}

@keyframes colorPulse {
    0%, 100% { filter: brightness(1) saturate(1); }
    25% { filter: brightness(1.05) saturate(1.1); }
    50% { filter: brightness(1) saturate(1); }
    75% { filter: brightness(0.95) saturate(0.9); }
}

