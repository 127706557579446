.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.page-background {
  background: linear-gradient(
    135deg,
    #FFF0F5 0%,
    #FFB6C1 25%,
    #FFC0CB 50%,
    #FFB6C1 75%,
    #FFF0F5 100%
  );
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Arial, sans-serif;
  padding: 20px;
  overflow: hidden; /* Prevents scrolling */
}

/* Planes */
.plane-container {
  position: absolute;
  top: 35%;
  animation: flyAcross 20s linear infinite;
  z-index: 2;
  pointer-events: none;
}

.plane {
  position: relative;
  font-size: 2.5rem;
  transform: rotate(-30deg);
}

.plane-trail {
  position: absolute;
  right: 100%;
  top: 50%;
  width: 50vw;
  height: 8px;
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 0.9),
    rgba(255, 255, 255, 0)
  );
  transform: translateY(-50%);
  filter: blur(8px);
  opacity: 0.7;
  animation: trailPulse 3s ease-in-out infinite;
}

@keyframes trailPulse {
  0% {
    height: 8px;
    opacity: 0.7;
  }
  50% {
    height: 12px;
    opacity: 0.9;
  }
  100% {
    height: 8px;
    opacity: 0.7;
  }
}

@keyframes flyAcross {
  0% {
    transform: translate3d(-50vw, 0, 0);
  }
  100% {
    transform: translate3d(calc(100vw + 100px), 0, 0);
  }
}

/* Clouds */
.clouds {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
}

.cloud {
  position: absolute;
  opacity: 0.8;
  filter: drop-shadow(2px 2px 5px rgba(255,255,255,0.3));
  left: 0;
}

.cloud1 { 
  animation: floatCloud1 50s linear infinite; 
  top: 5%; 
  font-size: 7.5rem; 
}

.cloud2 { 
  animation: floatCloud2 20s linear infinite;
  top: 35%; 
  font-size: 4.5rem; 
}

.cloud3 { 
  animation: floatCloud3 55s linear infinite; 
  top: 85%; 
  font-size: 6.2rem; 
}
.cloud4 { 
  animation: floatCloud4 15s linear infinite; 
  top: 15%; 
  font-size: 3.8rem; 
}

.cloud5 { 
  animation: floatCloud1 48s linear infinite;
  top: 25%; 
  font-size: 5.8rem; 
}
.cloud6 { 
  animation: floatCloud2 28s linear infinite;
  top: 65%; 
  font-size: 3.2rem; 
}
.cloud7 { 
  animation: floatCloud3 60s linear infinite;
  top: 45%; 
  font-size: 4.5rem; 
}
.cloud8 { 
  animation: floatCloud4 30s linear infinite;
  top: 75%; 
  font-size: 2.8rem; 
}
.cloud9 { 
  animation: floatCloud1 20s linear infinite;
  top: 12%; 
  font-size: 6.5rem; 
}
.cloud10 { 
  animation: floatCloud2 25s linear infinite;
  top: 55%; 
  font-size: 3.5rem; 
}
.cloud11 { 
  animation: floatCloud3 59s linear infinite;
  top: 92%; 
  font-size: 5.2rem; 
}
.cloud12 { 
  animation: floatCloud4 32s linear infinite;
  top: 8%; 
  font-size: 4.8rem; 
}

/* Landing Page Content */
.header {
  padding: 20px;
  position: relative;
  z-index: 1001;
}

.auth-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.center-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1001;
}

.welcome-text {
  font-size: 4rem;
  color: #4A7299;
  margin-bottom: 30px;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.1);
}

.button {
  padding: 12px 24px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 1.1rem;
}

.sign-up-button, .log-in-button {
  background-color: transparent;
  color: #4A7299;
  border: 2px solid #4A7299;
}

.plan-trip-button {
  background-color: #4A7299;
  color: white;
  padding: 15px 30px;
  font-size: 1.2rem;
}

.button:hover {
  transform: scale(1.05);
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
}

/* Animations */
@keyframes floatCloud1 {
  0% {
    transform: translate3d(-100px, 0, 0);
  }
  50% {
    transform: translate3d(calc(50vw - 100px), -80px, 0);
  }
  100% {
    transform: translate3d(calc(100vw + 100px), 0, 0);
  }
}

@keyframes floatCloud2 {
  0% {
    transform: translate3d(calc(100vw + 100px), 0, 0);
  }
  50% {
    transform: translate3d(50vw, 120px, 0);
  }
  100% {
    transform: translate3d(-100px, 0, 0);
  }
}

@keyframes floatCloud3 {
  0% {
    transform: translate3d(-200px, 0, 0);
  }
  50% {
    transform: translate3d(calc(50vw - 200px), -100px, 0);
  }
  100% {
    transform: translate3d(calc(100vw + 200px), 0, 0);
  }
}

@keyframes floatCloud4 {
  0% {
    transform: translate3d(calc(100vw + 120px), 0, 0);
  }
  50% {
    transform: translate3d(50vw, 60px, 0);
  }
  100% {
    transform: translate3d(-120px, 0, 0);
  }
}

/* Exit animation */
@keyframes cloudExit {
  0% {
    position: fixed;
    left: var(--current-x);
    top: var(--current-y);
    transform: translateY(0);
  }
  100% {
    position: fixed;
    left: var(--current-x);
    top: var(--current-y);
    transform: translateY(-200vh);
  }
}

.clouds.flying .cloud {
  animation: cloudExit 0.5s forwards cubic-bezier(0.4, 0, 1, 1) !important;
  animation-delay: var(--exit-delay) !important;
}

.clouds.flying .cloud1 { animation-delay: 0s !important; }
.clouds.flying .cloud3 { animation-delay: 0.2s !important; }
.clouds.flying .cloud4 { animation-delay: 0.3s !important; }
.clouds.flying .cloud5 { animation-delay: 0.15s !important; }
.clouds.flying .cloud6 { animation-delay: 0.25s !important; }
.clouds.flying .cloud7 { animation-delay: 0.05s !important; }
.clouds.flying .cloud8 { animation-delay: 0.35s !important; }
.clouds.flying .cloud9 { animation-delay: 0.12s !important; }
.clouds.flying .cloud10 { animation-delay: 0.28s !important; }
.clouds.flying .cloud11 { animation-delay: 0.18s !important; }
.clouds.flying .cloud12 { animation-delay: 0.22s !important; }

/* Hover effects */
.cloud:hover {
  animation-play-state: paused;
}

/* Media Queries */
@media (max-width: 768px) {
  .welcome-text {
    font-size: 3rem;
  }
  
  .plane {
    font-size: 2rem;
  }
  
  .cloud {
    font-size: 3rem;
  }
}
