@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&family=Outfit:wght@700&display=swap');

.page-background {
  background: linear-gradient(
    135deg,
    #FFF0F5 0%,
    #FFB6C1 25%,
    #FFC0CB 50%,
    #FFB6C1 75%,
    #FFF0F5 100%
  );
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  padding: 20px;
  animation: gradientShift 10s ease infinite;
  background-size: 400% 400%;
}

@keyframes gradientShift {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.main-container {
  background-color: white;
  border-radius: 20px;
  padding: 40px;
  width: 100%;
  max-width: 500px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: containerAppear 0.5s cubic-bezier(0.17, 0.89, 0.32, 1.28);
  transform-origin: center bottom;
}

.logo {
  color: #FF69B4;
  font-size: 36px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
}

.heading {
  font-family: 'Outfit', sans-serif;
  font-weight: 700;
  color: #333;
  font-size: 28px;
  text-align: center;
  margin-bottom: 30px;
}

.input-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: transform 0.5s ease, opacity 0.5s ease;
  top: 0;
  left: 0;
  opacity: 1;
  pointer-events: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.input-wrapper.prev {
  transform: translateY(-100%);
  opacity: 0;
  pointer-events: none;
}

.input-wrapper.next {
  transform: translateY(100%);
  opacity: 0;
  pointer-events: none;
}

.input-wrapper.current {
  transform: translateY(0);
  opacity: 1;
  pointer-events: auto;
}

.input-wrapper.transitioning {
  pointer-events: none;
}

.label {
  color: #333;
  margin-bottom: 5px;
  width: 100%;
  max-width: 400px;
  display: block;
  font-weight: 700;
}

.input {
  width: 100%;
  max-width: 400px;
  padding: 12px;
  border: 2px solid #789DBC;
  border-radius: 15px;
  font-size: 16px;
  color: #333;
  box-sizing: border-box;
  margin-bottom: 15px;
  font-family: 'Roboto', sans-serif;
}

.input::placeholder {
  color: #999;
}

.button-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  width: 100%;
  max-width: 400px;
  margin-top: 20px;
}

.next-button, .back-button {
  padding: 12px 24px;
  border: none;
  border-radius: 24px;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.next-button {
  background-color: #789DBC;
  color: white;
  padding: 14px 28px; /* Slightly larger padding for the next button */
  font-size: 20px; /* Slightly larger font size for the next button */
}

.next-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(135deg, #789DBC, #A7C1D9);
  z-index: -1;
  transition: transform 0.3s ease;
  transform: scaleX(0);
  transform-origin: left;
}

.next-button:hover::before {
  transform: scaleX(1);
}

.next-button:hover {
  color: #4A7299;
}

.next-button:not(:hover)::before {
  transform-origin: left;
}

.next-button:disabled {
  background-color: #ccc;
  color: #666;
  cursor: not-allowed;
}

.next-button:disabled::before {
  display: none;
}

.back-button {
  background-color: white;
  color: #789DBC;
  border: 2px solid #789DBC;
}

.back-button:hover {
  background-color: #789DBC;
  color: white;
}

.step-container {
  width: 100%;
  max-width: 400px;
  position: relative;
  height: 180px; /* Adjust as needed */
  overflow: hidden;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.step-content {
  position: absolute;
  width: 100%;
  transition: transform 0.4s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 0.3s ease;
  top: 0;
  left: 0;
  opacity: 1;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.step-content.active {
  transform: translateY(0);
  opacity: 1;
  transition: transform 0.4s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 0.3s ease 0.1s;
  z-index: 2;
}

.step-content.inactive-next {
  transform: translateY(30%); 
  opacity: 0;
  pointer-events: none;
}

.step-content.inactive-prev {
  transform: translateY(-30%); 
  opacity: 0;
  pointer-events: none;
}

/* Update this rule to match the new transform value */
.step-container .step-content.inactive-next {
  top: 30%;
  transition: transform 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55), opacity 0.3s ease, top 0s linear 0.5s;
}

/* Add this class to prevent scrolling during transitions */
.no-scroll {
  overflow: hidden;
}

/* Add this at the end of the file */
@keyframes containerAppear {
  0% {
    opacity: 0;
    transform: scale(0.8) translateY(30px) rotate(-3deg);
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }
  70% {
    opacity: 1;
    transform: scale(1.03) translateY(-5px) rotate(1deg);
  }
  100% {
    opacity: 1;
    transform: scale(1) translateY(0) rotate(0);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
}

/* Add these styles to your existing CSS */
.location-input {
  width: 100%;
}

/* Style the Google Places Autocomplete dropdown */
.pac-container {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd;
  font-family: 'Roboto', sans-serif;
  margin-top: 5px;
}

.pac-item {
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.pac-item:hover {
  background-color: #f5f5f5;
}

.pac-item-query {
  font-size: 14px;
  color: #333;
}

.pac-matched {
  font-weight: bold;
  color: #4A7299;
}
