@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&family=Outfit:wght@700&display=swap');

.page-background {
  background: linear-gradient(
    135deg,
    #FFF0F5 0%,
    #FFB6C1 25%,
    #FFC0CB 50%,
    #FFB6C1 75%,
    #FFF0F5 100%
  );
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  padding: 20px;
}

.main-container {
  background-color: white;
  border-radius: 20px;
  padding: 40px;
  width: 100%;
  max-width: 500px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  color: #FF69B4;
  font-size: 36px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
}

.heading {
  font-family: 'Outfit', sans-serif;
  font-weight: 700;
  color: #333;
  font-size: 28px;
  text-align: center;
  margin-bottom: 30px;
}

.input-container {
  width: 100%;
  max-width: 400px; /* Adjust this value as needed */
}

.trip-making-input-wrapper {
  position: static;
  opacity: 1;
  pointer-events: auto;
  margin-bottom: 20px;
  text-align: left;
  width: 100%;
}

.label {
  color: #333;
  margin-bottom: 5px;
  width: 100%;
  display: block;
  font-weight: 700;
  text-align: left;
}

.input {
  width: 100%;
  padding: 12px;
  border: 2px solid #789DBC;
  border-radius: 15px;
  font-size: 16px;
  color: #333;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

.input::placeholder, .select::placeholder {
  color: #999;
}

.select {
  appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg fill="%23333" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
  background-repeat: no-repeat;
  background-position: right 10px top 50%;
  background-size: 20px auto;
}

.button-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  width: 100%;
  max-width: 400px;
  margin-top: 20px;
}

.next-button, .back-button {
  padding: 12px 24px;
  border: none;
  border-radius: 24px;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.next-button {
  background-color: #789DBC;
  color: white;
}

.next-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #A7C1D9;
  z-index: -1;
  transition: transform 0.3s ease;
  transform: scaleX(0);
  transform-origin: left;
}

.next-button:hover::before {
  transform: scaleX(1);
}

.next-button:hover {
  color: #4A7299;
}

.next-button:not(:hover)::before {
  transform-origin: right;
}

.next-button:disabled {
  background-color: #ccc;
  color: #666;
  cursor: not-allowed;
}

.next-button:disabled::before {
  display: none;
}

.back-button {
  background-color: white;
  color: #789DBC;
  border: 2px solid #789DBC;
}

.back-button:hover {
  background-color: #789DBC;
  color: white;
}

.input.disabled {
  background-color: #f0f0f0;
  cursor: not-allowed;
  opacity: 0.6;
}

.no-scroll {
  overflow: hidden;
}
